




































import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'

@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    Title
  }
})
export default class Rating extends Vue {
  private routeName = 'demographic welcome'
  selectedItemQvue = 6
  selectedItemAdvisor = 6

  private created (): void {
    if (window.innerWidth < 767) {
      this.$root.$on('mobile-go-back', () => {
        this.$router.push('/signup/terms-policy')
      })
    }
  }

  private rateQvue (index: number): void {
    this.selectedItemQvue = index
  }

  private rateAdvisor (index: number): void {
    this.selectedItemAdvisor = index
  }

  private submitRating ():void {
    const rating = {
      experienceRating: (6 - this.selectedItemQvue),
      advisorsRating: (6 - this.selectedItemAdvisor)
    }
    this
      .$store
      .dispatch('walkthrough/sendFeedback', rating)
      .then(() => {
        this.$router.push('/rating/success')
      })
      .catch((err) => {
        console.error(err)
        // alert('There is something wrong. Please try again.')
      })
  }
}
